<script setup>
 const { card } = defineProps({
  card: {
   type: Object,
  },
 });
</script>

<template>
 <MessLink v-if="card.linkToProduct" :href="card.linkToProduct" class="store-card">
  <MessImage v-if="card.image" :image="card.image" />
  <div class="content-wrapper">
   <MessHtml :html="card.title" tag="h3" class="h4" />
   <div class="price">
    <MessHtml v-if="card.price" :html="`$${card.price}`" tag="p" class="p body-lg" />
    <p v-if="card.memberPrice" class="member-price">${{ card.memberPrice }} member price</p>
   </div>
  </div>
 </MessLink>
</template>

<style lang="scss">
 .store-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 1500ms ease-in-out;
  transition-delay: 250ms;

  &:not(:last-of-type) {
   margin-bottom: 3rem;
   @media (min-width: $tablet) {
    margin-bottom: 0;
   }
  }
  .mess-image {
   width: 100%;
   max-width: 375px;
   img {
    transition: var(--transition);
    filter: grayscale(0%);
    padding: 0 70px;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
   }
   @media (min-width: $tablet) {
    max-width: 440px;
   }
  }
  .h4 {
   margin-bottom: 1rem;
  }
  .content-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   background-color: var(--white);
   position: relative;
   padding-top: 2em;
   transition: var(--transition);
   width: 100%;
   z-index: 2;

   @media (min-width: $tablet) {
    align-items: start;
   }
  }
  .price {
   display: flex;
   width: 100%;
   align-items: center;
   gap: 2rem;
   justify-content: space-between;

   p,
   .body-lg {
    font-weight: 600;
   }
   p.member-price {
    color: var(--red);
    padding-right: 2rem;
    font-size: 14px;
   }
  }
  &:hover {
   .content-wrapper {
    transform: translate(0, -7rem);
   }
   .mess-image {
    img.full-sized-image {
     scale: 1.025;
     filter: grayscale(100%);
    }
   }
  }
 }
 .v-aos-before-enter {
  .store-card {
   transform: translate(0, 2rem);
   filter: grayscale(100%);
   opacity: 0;
  }
 }
 .v-aos-entered {
  .store-card {
   transform: translate(0%, 0%);
   opacity: 1;
   filter: none;
  }
 }
</style>
